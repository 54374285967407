import React, { useState, useEffect, useRef } from 'react';
import './navbar.css';

const Dropdown = ({ title, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <dxiv className="appnav-dropdown" ref={dropdownRef}>
      <button className="appnav-dropdown-toggle" onClick={toggleDropdown}>
        {title}&#9660;
      </button>
      <div className={`appnav-dropdown-menu ${isOpen ? 'appnav-is-active' : ''}`}>
        {items.map((item, index) => (
          <a style={{fontSize: '2rem'}} key={index} href={item.href}>{item.label}</a>
        ))}
      </div>
    </dxiv>
  );
};

export default Dropdown;
