import React, { useState, useEffect } from 'react';
import Dropdown from './dropdown';
import { useI18nSection, useLanguage, useSetLanguage } from "../../utils/languageUtils";

import './navbar.css';

const Navbar = () => {
  const languageOptions = {
    en: {
      flag: "fi fi-gb width-size",
      name: "English",
      selectLanguage: "",
    },
    zh: {
      flag: "fi fi-cn width-size",
      name: "中文",
      selectLanguage: "",
    },
    es: {
      flag: "fi fi-es width-size",
      name: "Español",
      selectLanguage: "",
    },
    jp: {
      flag: "fi fi-jp width-size",
      name: "日本語",
      selectLanguage: "",
    },
    vi: {
      flag: "fi fi-vn width-size",
      name: "Tiếng Việt",
      selectLanguage: "",
    },
    de: {
      flag: "fi fi-de width-size",
      name: "Deutsch",
      selectLanguage: "",
    },
  };
  const currentLanguage = useLanguage()

  const sectionText = useI18nSection('nav')
  const setLanguage = useSetLanguage()

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);

  const handleChangeLanguage = (language) => {
    setLanguage(language);
    setIsLanguageOpen(false)
  };

  const closeMenu = () => {
    setIsMenuOpen(false)
    setIsLanguageOpen(false)
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleLanguageDrpdwn = () =>{
    setIsLanguageOpen(!isLanguageOpen);
  }

  const scrollToBuySection = () => {
    // Find the target section to scroll to
    let section = null;
    // if(isMobile){
    //   section = document.getElementById('buyForm');
    // }else{
      section = document.getElementById('intro');
    // }
    if (!section) {
      window.location = "/"
      return
    }
    // Scroll to the section
    section.scrollIntoView({ behavior: 'smooth' });
    closeMenu()
  };

  return (
    <nav className={`appnav-navbar  sticky`}>
      <div className="appnav-navbar-brand">
        <a href="/">
          <span style={{fontSize:"50px"}} className="appnav-logo"> Ghosted Kat </span>
        </a>        
        <button className="appnav-menu-toggle" onClick={toggleMenu}>
          <div className={`appnav-hamburger ${isMenuOpen ? 'appnav-is-active' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      
      <div className={`appnav-navbar-menu ${isMenuOpen ? 'appnav-is-active' : ''}`}>
        <a href="#about">{sectionText.about}</a>
        <a href="#howtobuy">{sectionText.howToBuy}</a>
      </div>
      <div className={`appnav-navbar-right ${isMenuOpen ? 'appnav-is-active' : ''}`}>
        <div className="appnav-lang-login-container">
          <button
              onClick={scrollToBuySection}
              className="appnav-login">{sectionText.buyNow}</button>

          <div className="appnav-lang-dropdown">
            <div className="appnav-lang-custom-dropdown" onClick={toggleLanguageDrpdwn}>
              <span className={languageOptions[currentLanguage].flag}></span>
              <span className={`lang-dropdown-icon ${isLanguageOpen ? 'open' : ''}`}>&#9660;</span>
            </div>
            <div className={`lang-dropdown-content ${isLanguageOpen ? 'open' : ''}`}>
              {Object.keys(languageOptions).map((language) => (
                  <div key={language} className="lang-dropdown-item" onClick={() => handleChangeLanguage(language)}>
                    <span className={languageOptions[language].flag}></span>
                    <span>{languageOptions[language].name}</span>
                  </div>
              ))}
            </div>
          </div>
          <div className="appnav-social">
              <a href="https://x.com/ghostedkat">
                <img src="img/kat/x.png" width={"20"} height={"20"}/>
              </a>
              <a href="https://t.me/ghostedkat">
                <img src="img/kat/telegram.svg" width={"20"} height={"20"}/>
              </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
