import React, { useState, useEffect } from "react";

export const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024); // Adjust the breakpoint as needed
    };

    handleResize(); // Check initial viewport width
    window.addEventListener("resize", handleResize); // Add event listener for window resize

    return () => window.removeEventListener("resize", handleResize); // Cleanup on unmount
  }, []);

  return (
    // <div id="team">
    //   <div class="section footer">
    //     <div class="container">
    //       <div class="row">
    //         <div class="col-12 col-lg-12 text-center">
    //           <h3 translate="" class="font-14 text-light mb-0">DISCLAIMER</h3>
    //           <p translate="" class="para font-14 px-sm-3 pt-2 pb-0 text-light ">Investing in 'WienerAI' is risky and may lead to loss of capital. Returns are not guaranteed. Not financial advice. DYOR.</p>
    //           <p translate="" class="para font-20 text-white">© 2024 WienerAI — All Rights Reserved.</p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <></>
  );
};
