import React, { useState, useEffect, useMemo } from "react";
import { Navbar, Nav, Container, Row, Col, Card } from "react-bootstrap";
import {
  calculateUSDNeeded,
  calculateTokenOutput,
  calculateTokensForBNB,
  calculateBNBNeeded,
  isValidNumber,
  truncateMiddle,
  CONST,
  useWallet,
} from "../services/wallet-service";
import configs from "../config.main.json";
import { useCountdown } from "../services/utils";
import { useTokenInfo, getUserPurchaseInfo } from "../services/token-service";
import { useI18nSection } from "../utils/languageUtils";
import "./header.css";
import { CURRENCIES, VALID_NETWORKS, NETWORK_OTIONS, CURR_CODE } from "../ducks/nativeNetworkDuck";
import { useWalletETH } from "../services/wallet-service1";
import { useNativeNetwork, useSetNativeNetwork } from "../utils/nativeNetworkUtils";
export const Header = () => {
  const sectionText = useI18nSection("buyForm");

  const nativeNetwork = useNativeNetwork()
  const setNativeNetwork = useSetNativeNetwork()
  const walletEth = useWalletETH(nativeNetwork, configs)
  const currList = CURRENCIES[nativeNetwork]
  const remainNetwork = VALID_NETWORKS.filter(x => x!== nativeNetwork)[0]
  const nextNetworkName = remainNetwork === 'bsc' ? sectionText?.bnbOption : sectionText?.ethOption

  const [useTotalBought, setUseTotalBought] = useState(0);

  const [selectedCurr, setSelectedCurr] = useState();

  const tokenInfo = useTokenInfo(configs);
  const [tokenInput, setTokenInput] = useState("");
  const [currencyInput, setCurrencyInput] = useState("");

  const [isClicked, setIsClicked] = useState(false);
  const coolDownTime = 300; // milliseconds
  const endSaleTime = useMemo(() => new Date(configs['endSaleTime']).getTime(), []); // Memoize endSaleTime
  const { days, hours, minutes, seconds } = useCountdown(endSaleTime);




  useEffect(() => {
    if (isClicked) {
      const timeoutId = setTimeout(() => setIsClicked(false), coolDownTime);
      return () => clearTimeout(timeoutId);
    }
  }, [isClicked]);

  useEffect(()=>{
      setSelectedCurr(currList[0])
      setTokenInput('')
      setCurrencyInput('')
  }, [currList])

 

  useEffect(() => {
   

    const loadPurchaseInfo = async () => {
      if (!walletEth.currentAddress) {
        return;
      }

      try {
        
        const info = await getUserPurchaseInfo(configs, walletEth.currentAddress)
        if (info) {
          setUseTotalBought(info)
        }
      }
      catch (err) {

      }
    }
    loadPurchaseInfo()
  }, [walletEth.currentAddress, configs]); // Empty dependency array ensures this effect runs only once

  
  const toggleNativeNetwork = () => {
    setNativeNetwork(remainNetwork)
  }

  const handleTokenInputChange = (event) => {
    const { value } = event.target;
    if (value === "") {
      setTokenInput(value);
      setCurrencyInput("")
      return
    }
    if (!walletEth?.tokenPriceInUsdt) {
      return
    }
    // Regular expression to allow only numeric and float values
    if (/^\d*\.?\d*$/.test(value) && isValidNumber(value)) {
        setTokenInput(value);
        if (selectedCurr.curr === CURR_CODE.BNB || selectedCurr.curr === CURR_CODE.ETH) {
          setCurrencyInput(calculateBNBNeeded(value, selectedCurr.curr === CURR_CODE.BNB ? walletEth?.bnbPrice : walletEth?.ethPrice , walletEth?.tokenPriceInUsdt))
        }
        else {
          setCurrencyInput(calculateUSDNeeded(value, walletEth?.tokenPriceInUsdt))
        }
    }
  };


  const handleCurrencyInputChange = (event) => {
    
    const { value } = event.target;
    if (value === "") {
      setTokenInput("");
      setCurrencyInput(value)
      return
    }
    if (!walletEth?.tokenPriceInUsdt) {
      return
    }
    // Regular expression to allow only numeric and float values
    if (/^[0-9]*[.]?[0-9]*$/.test(value) && isValidNumber(value)) {
      setCurrencyInput(value);
      if (selectedCurr.curr === CURR_CODE.BNB || selectedCurr.curr === CURR_CODE.ETH) {
        setTokenInput(calculateTokensForBNB(value, selectedCurr.curr === CURR_CODE.BNB ? walletEth?.bnbPrice : walletEth?.ethPrice,  walletEth?.tokenPriceInUsdt))
      }
      else {
        setTokenInput(calculateTokenOutput(value, walletEth?.tokenPriceInUsdt))
      }
    }
  };

  const handleKeyPressToken = (event) => {
    // Allow the dot character (.) only if it doesn't already exist in the input value
    if (event.key === '.' && tokenInput.includes('.')) {
      event.preventDefault();
    }
  };
  const handleKeyPressCurr = (event) => {
    // Allow the dot character (.) only if it doesn't already exist in the input value
    if (event.key === '.' && currencyInput.includes('.')) {
      event.preventDefault();
    }
  };

  const handleBuyTokenClick = async () => {
    if (!isClicked) {
      setIsClicked(true);
      // Your button click logic here
      if (selectedCurr.curr === CURR_CODE.BNB || selectedCurr.curr === CURR_CODE.ETH) {
          await walletEth.buyTokens(currencyInput)
      }
      else {
          await walletEth.buyTokensUSDT(currencyInput);
      }
    }
  };



  const handleSwitchOption = (idx) => {
    const curr = currList[idx]
    setSelectedCurr(curr)

    setTokenInput('')
    setCurrencyInput('')

  };


  return (
    <div id="intro" className="intro">
      {/* <div className="container"> */}
      <div
        style={{ paddingLeft: "12px", paddingRight: "12px", display: "flex" }}
      >
        <div className="bannerSec">
          <Row style={{ flex: 1 }}>
            <Col md={7} sm={12} className="col-xs-12"></Col>
            <Col
              md={5}
              sm={12}
              className="col-xs-12"
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                alignItems:'center'
              }}
            >
              <div className="walletBox" id="walletBox">
                <div className="walletBox_header">
                  <h2>{sectionText?.intro}</h2>
                  
                  
                    <div className="d-flex align-items-center justify-content-center w-100 gap-3 fw-regular font-20 counter mb-3  ">
                      <div className="rounded-3 time-card text-center  ">
                        <div className="indicator  ">{sectionText.day}</div>
                        <div id="days" className="value  ">{days}</div>
                        {/* <img className="colon-item" src="./img/colon.svg" /> */}
                      </div>
                      <div className="rounded-3 time-card text-center  ">
                        <div className="indicator  ">{sectionText.hrs}</div>
                        <div id="hours" className="value  ">{hours}</div>
                        {/* <img className="colon-item" src="./img/colon.svg" /> */}
                      </div>
                      <div className="rounded-3 time-card text-center  ">
                        <div className="indicator  ">{sectionText.mins}</div>
                        <div id="minutes" className="value  ">{minutes}</div>
                        {/* <img className="colon-item" src="./img/colon.svg" /> */}
                      </div>
                      <div className="rounded-3 time-card text-center  ">
                        <div className="indicator  ">{sectionText.sec}</div>
                        <div id="seconds" className="value  ">{seconds}</div>
                      </div>
                    </div>
                    <p>1 $KAT = ${walletEth?.tokenPriceInUsdt}</p>
                    <p>{sectionText?.funRaised} $5m+</p>
                    {walletEth.currentAddress &&
                        <p>
                          {sectionText?.boughtAmount} $KAT = <span>{useTotalBought}</span>{" "}
                        </p>
                    }
                    {/* {wallet.currentAddress &&
                        <p>
                          {sectionText?.boughtAmount} ${configs.targetToken.symbol} = <span>{useTotalBought}</span>{" "}
                        </p>
                    } */}
                </div>
                <div className="walletBox_paymentBTN">
                    {currList.map((curr, idx) => {
                        return(

                            <button key={idx} 
                              className={`paymentBTN ${selectedCurr?.text === curr.text ? 'active' : ''}`}
                              onClick={() => handleSwitchOption(idx)}
                            >
                              <img
                                className="icon"
                                src={curr.imageSrc}
                              ></img>{" "}
                              {curr.text}
                            </button>
                        )
                    })}
                </div>
                <div className="walletBox_payment">
                  <div className="walletBox_payment1">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <p style={{margin : 0}}>{sectionText?.pay} {selectedCurr?.text} </p>
                    </div>
                    <div className="walletBox_payment_input">
                      <input 
                          value={currencyInput}
                          onChange={handleCurrencyInputChange}
                          onKeyPress={handleKeyPressCurr}
                          type="text"
                          placeholder="0"
                          className=""  />
                      <img
                        className="icon"
                        src={selectedCurr?.icon}
                      />
                    </div>
                  </div>
                  <div className="walletBox_payment2">
                  <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <p style={{margin : 0}}>$KAT {sectionText?.get}</p>
                    </div>
                    <div className="walletBox_payment_input">
                      <input 
                          value={tokenInput}
                          onChange={handleTokenInputChange}
                          type="text"
                          onKeyPress={handleKeyPressToken}
                          placeholder="0"                          
                          className="hoverInput" />
                      <img
                        className="icon"
                        src="/img/kat/og.png"
                      />
                    </div>
                  </div>
                </div>
                { !walletEth.currentAddress && 
                    <div className="walletBox_connect">
                      <button 
                        onClick={walletEth.connect}
                        className="walletBox_connect_btn">
                          {sectionText?.wallet}
                      </button>
                      <button 
                        onClick={toggleNativeNetwork}
                        className="walletBox_connect_btn">
                        <p>
                        <img
                            className="icon"
                            src={NETWORK_OTIONS[remainNetwork].img}
                          />
                          {"  "}
                          {nextNetworkName} 
                        </p>
                      </button>
                    </div>
                }
                
                { walletEth.currentAddress && 

                    <div className="walletBox_connect">
                      <button 
                        onClick={handleBuyTokenClick}
                        className="walletBox_connect_btn">
                        {sectionText?.buyNow}
                      </button>
                      <button 
                        onClick={() => walletEth.swicthNativeNetwork(remainNetwork)}                    
                        className="walletBox_connect_btn">
                        <p>
                        <img
                            className="icon"
                            src={NETWORK_OTIONS[remainNetwork].img}
                          />
                          {"  "}
                          {nextNetworkName} 
                        </p>
                      </button>
                    </div>
                }

                <div className="walletBox_footer">
                  <p>
                    Powered by{" "}
                    <img
                      src="https://sealana.io/assets/images/svg-icons/W3P_Black.svg"
                      alt="w3p"
                    />
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    // </div>
  );
};
