import React from "react";
import { Navbar, Nav, Container, Row, Col, Card } from "react-bootstrap";
import { useI18nSection } from "../../utils/languageUtils";
import "./outmore.css";
export const OutMore = () => {
  const sectionText = useI18nSection("about");

  return (
    <div id="about">
      <div  className="">
        <div className="wrapper about">
          <img
            className="wrapper_img "
            src="https://sealana.io/assets/images/about-thread.svg"
            alt=""
            srcSet=""
          />
          <div className="about-wrapper">
            <div className="about-frame ">
              <div className="about-info">
                <div className="title-wrapper">
                  <h2>{sectionText?.title}</h2>
                  <img
                    src="img/kat/about.png"
                    alt="sealana"
                    srcSet=""
                    style={{width: "200px", height: "90px"}}
                  />
                </div>
                <p>
                  {sectionText?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
